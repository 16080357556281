import { Link } from "react-router-dom";
import Rating from "@mui/material/Rating";
import { IoIosClose } from "react-icons/io";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import emprtCart from "../../assets/images/emptyCart.png";
import { MyContext } from "../../App";
import { useContext, useEffect, useState } from "react";
import { deleteData, editData, fetchDataFromApi } from "../../utils/api";
import { IoBagCheckOutline } from "react-icons/io5";
import { FaHome } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

// QuantityBox Component
const QuantityBox = ({ quantity, onQuantityChange }) => {
  const handleIncrement = () => {
    onQuantityChange(quantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      onQuantityChange(quantity - 1);
    }
  };

  return (
    <div className="quantityBox">
      <IconButton onClick={handleDecrement} disabled={quantity <= 1}>
        <RemoveIcon />
      </IconButton>
      <span>{quantity}</span>
      <IconButton onClick={handleIncrement}>
        <AddIcon />
      </IconButton>
    </div>
  );
};

const Cart = () => {
  const [cartData, setCartData] = useState([]); // Ensure cartData is an array
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLogin, setIsLogin] = useState(false);

  const context = useContext(MyContext);
  const history = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      setIsLogin(true);
    } else {
      history("/signIn");
    }

    const user = JSON.parse(localStorage.getItem("user"));

    // Function to fetch cart data
    const fetchCartData = () => {
      fetchDataFromApi(`/api/cart?userId=${user?.userId}`).then((res) => {
        if (Array.isArray(res)) {
          setCartData(res);
        } else {
          console.error("Received data is not an array:", res);
          setCartData([]); // Reset to an empty array if data is not valid
        }
      });
    };

    // Fetch the initial cart data
    fetchCartData();

    // Poll for updates every 30 seconds (30000 ms)
    const interval = setInterval(fetchCartData, 30000);

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, [history]);

  // Recalculate the cart total whenever cartData changes
  useEffect(() => {
    if (cartData.length > 0) {
      const newTotal = cartData.reduce(
        (acc, item) => acc + (item.price || 0) * (item.quantity || 0),
        0
      );
      setTotal(newTotal);
    }
  }, [cartData]);

  const updateQuantity = (item, newQuantity) => {
    setIsLoading(true);
    const user = JSON.parse(localStorage.getItem("user"));

    const updatedItem = {
      ...item,
      quantity: newQuantity,
      subTotal: parseInt((item.price || 0) * newQuantity),
      userId: user?.userId,
    };

    editData(`/api/cart/${item._id}`, updatedItem).then(() => {
      fetchDataFromApi(`/api/cart?userId=${user?.userId}`).then((res) => {
        if (Array.isArray(res)) {
          setCartData(res);
        } else {
          console.error("Received data is not an array:", res);
          setCartData([]);
        }
        setIsLoading(false);
      });
    });
  };

  const removeItem = (id) => {
    setIsLoading(true);
    deleteData(`/api/cart/${id}`).then(() => {
      context.setAlertBox({
        open: true,
        error: false,
        msg: "Item removed from cart!",
      });

      const user = JSON.parse(localStorage.getItem("user"));
      fetchDataFromApi(`/api/cart?userId=${user?.userId}`).then((res) => {
        if (Array.isArray(res)) {
          setCartData(res);
        } else {
          console.error("Received data is not an array:", res);
          setCartData([]);
        }
        setIsLoading(false);
      });

      context.getCartData();
    });
  };

  return (
    <>
      <section className="section cartPage">
        <div className="container">
          <h2 className="hd mb-1">Your Cart</h2>
          <p>
            There are <b className="text-red">{cartData?.length}</b> products in your cart
          </p>

          {cartData?.length !== 0 ? (
            <div className="row">
              <div className="col-md-9 pr-5">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th width="35%">Product</th>
                        <th width="15%">Unit Price</th>
                        <th width="25%">Quantity</th>
                        <th width="15%">Subtotal</th>
                        <th width="10%">Remove</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cartData.map((item, index) => (
                        <tr key={index}>
                          <td width="35%">
                            <Link to={`/product/${item?.productId}`}>
                              <div className="d-flex align-items-center cartItemimgWrapper">
                                <div className="imgWrapper">
                                  <img
                                    src={item?.image}
                                    className="w-100"
                                    alt={item?.productTitle}
                                  />
                                </div>
                                <div className="info px-3">
                                  <h6>
                                    {item?.productTitle?.substr(0, 30) + "..."}
                                  </h6>
                                  <Rating
                                    name="read-only"
                                    value={item?.rating}
                                    readOnly
                                    size="small"
                                  />
                                </div>
                              </div>
                            </Link>
                          </td>
                          <td width="15%">Rwf {(item.price || 0).toLocaleString()}</td>
                          <td width="25%">
                            <QuantityBox
                              quantity={item?.quantity || 1}
                              onQuantityChange={(newQuantity) =>
                                updateQuantity(item, newQuantity)
                              }
                            />
                          </td>
                          <td width="15%">Rwf {(item.subTotal || 0).toLocaleString()}</td>
                          <td width="10%">
                            <span
                              className="remove"
                              onClick={() => removeItem(item?._id)}
                            >
                              <IoIosClose />
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="col-md-3">
                <div className="card border p-3 cartDetails">
                  <h4>CART TOTALS</h4>

                  <div className="d-flex align-items-center mb-3">
                    <span>Subtotal</span>
                    <span className="ml-auto text-red font-weight-bold">
                      Rwf {total.toLocaleString()}
                    </span>
                  </div>

                  <div className="d-flex align-items-center mb-3">
                    <span>Shipping</span>
                    <span className="ml-auto">
                      <b>Free</b>
                    </span>
                  </div>

                  <div className="d-flex align-items-center mb-3">
                    <span>Estimate for</span>
                    <span className="ml-auto">
                      <b>Rwanda</b>
                    </span>
                  </div>

                  <div className="d-flex align-items-center">
                    <span>Total</span>
                    <span className="ml-auto text-red font-weight-bold">
                      Rwf {total.toLocaleString()}
                    </span>
                  </div>

                  <br />
                  <Link to="/checkout">
                    <Button className="btn-blue bg-red btn-lg btn-big">
                      <IoBagCheckOutline /> &nbsp; Checkout
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            <div className="empty d-flex align-items-center justify-content-center flex-column">
              <img src={emprtCart} width="150" alt="Empty Cart" />
              <h3>Your Cart is currently empty</h3>
              <br />
              <Link to="/">
                <Button className="btn-blue bg-red btn-lg btn-big btn-round">
                  <FaHome /> &nbsp; Continue Shopping
                </Button>
              </Link>
            </div>
          )}
        </div>
      </section>

      {isLoading && <div className="loadingOverlay"></div>}
    </>
  );
};

export default Cart;
