import React, { useContext } from "react";
import { Swiper } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';

import { MyContext } from "../../App";

const HomeCat = (props) => {
    const context = useContext(MyContext);

    return (
        <section className="homeCat pb-2">
            <div className="container">
                {/* The Swiper container is still here, but all slides and content inside are removed */}
                <Swiper
                    slidesPerView={9}
                    spaceBetween={8}
                    navigation={context.windowWidth > 992 ? true : false}
                    slidesPerGroup={context.windowWidth > 992 ? 3 : 1}
                    modules={[Navigation]}
                    loop={false}
                    className="mySwiper"
                    breakpoints={{
                        320: {
                            slidesPerView: 3,
                            spaceBetween: 10,
                        },
                        500: {
                            slidesPerView: 5,
                            spaceBetween: 10,
                        },
                        768: {
                            slidesPerView: 8,
                            spaceBetween: 10,
                        }
                    }}
                >
                    {/* No more SwiperSlide or content here */}
                </Swiper>
            </div>
        </section>
    );
};

export default HomeCat;
