import React, { useEffect, useState, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import Rating from '@mui/material/Rating';
import { MyContext } from '../../App';
import sidebarGif from './sidebar-gif.gif'; // Import the GIF

const Sidebar = ({ isOpenFilter, filterData, filterByPrice, filterByRating }) => {
    const [priceRange, setPriceRange] = useState([100, 100000]);
    const [selectedSubCatId, setSelectedSubCatId] = useState('');
    const [selectedFilterSubCat, setSelectedFilterSubCat] = useState('');
    const [isFilterOpen, setIsFilterOpen] = useState(isOpenFilter);

    const context = useContext(MyContext);
    const { id } = useParams();

    useEffect(() => {
        setSelectedSubCatId(id);
    }, [id]);

    useEffect(() => {
        setIsFilterOpen(isOpenFilter);
    }, [isOpenFilter]);

    const handleChange = (event) => {
        const value = event.target.value;
        setSelectedFilterSubCat(value);
        filterData(value);
        setSelectedSubCatId(value);
    };

    useEffect(() => {
        filterByPrice(priceRange, selectedSubCatId);
    }, [priceRange, selectedSubCatId]);

    return (
        <div className={`sidebar ${isFilterOpen ? 'open' : ''}`}>
            <div className="filterBox">
                <h6>PRODUCT CATEGORIES</h6>
                <div className='scroll'>
                    <RadioGroup
                        aria-labelledby="product-categories"
                        name="product-categories"
                        value={selectedFilterSubCat}
                        onChange={handleChange}
                    >
                        {context?.subCategoryData?.length > 0 && context.subCategoryData.map(item => (
                            <FormControlLabel 
                                key={item.id} 
                                value={item.id} 
                                control={<Radio />} 
                                label={item.name} 
                            />
                        ))}
                    </RadioGroup>
                </div>
            </div>

            <div className="filterBox">
                <h6>FILTER BY PRICE</h6>
                <RangeSlider 
                    value={priceRange} 
                    onInput={setPriceRange} 
                    min={100} 
                    max={60000} 
                    step={5} 
                />
                <div className='d-flex pt-2 pb-2 priceRange'>
                    <span>From: <strong className='text-dark'>{priceRange[0]} Rwf</strong></span>
                    <span className='ml-auto'>To: <strong className='text-dark'>{priceRange[1]} Rwf</strong></span>
                </div>
            </div>

            <div className="filterBox">
                <h6>FILTER BY RATING</h6>
                <div className='scroll pl-0'>
                    <ul>
                        {[5, 4, 3, 2, 1].map(rating => (
                            <li key={rating} onClick={() => filterByRating(rating, selectedSubCatId)} className='cursor'>
                                <Rating name={`rating-${rating}`} value={rating} readOnly size="small" />
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            <br />
            <Link to="#">
                <img 
                    src={sidebarGif} 
                    alt='Promotional Banner' 
                    className='w-100' 
                />
            </Link>
        </div>
    );
};

export default Sidebar;
