import axios from "axios";

// Retrieve token from localStorage
const getToken = () => localStorage.getItem("token");

// Base URL from environment variable
const apiUrl = process.env.REACT_APP_API_URL;
if (!apiUrl) {
    throw new Error('API URL is not defined. Please check your environment variables.');
}

// A generic function for making API calls
const makeApiCall = async (method, url, data = null) => {
    try {
        const config = {
            method,
            url: `${apiUrl}${url}`,
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                'Content-Type': 'application/json',
            },
        };
        if (data) {
            config.data = data; // Attach data for POST, PUT, DELETE requests
        }
        const response = await axios(config);
        return response.data; // Return the response data directly
    } catch (error) {
        if (error.response) {
            // Handle API error response
            console.error(`API error (${error.response.status}): ${error.response.data.msg}`);
            return { status: false, msg: error.response.data.msg || 'An error occurred.' };
        } else if (error.request) {
            // Handle no response
            console.error('No response from server:', error.request);
            return { status: false, msg: 'No response from server.' };
        } else {
            // Handle other errors (e.g., axios setup)
            console.error('Error setting up request:', error.message);
            return { status: false, msg: 'An error occurred setting up the request.' };
        }
    }
};

// Fetch data from API
export const fetchDataFromApi = (url) => makeApiCall('GET', url);

// Post data to API
export const postData = (url, formData) => makeApiCall('POST', url, formData);

// Edit data in API
export const editData = (url, updatedData) => makeApiCall('PUT', url, updatedData);

// Delete data from API
export const deleteData = (url, data) => makeApiCall('DELETE', url, data);

// Upload an image to the API
export const uploadImage = (url, formData) => makeApiCall('POST', url, formData);

// Delete images from the API
export const deleteImages = (url, image) => makeApiCall('DELETE', url, image);
