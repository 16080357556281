import { FaMinus, FaPlus } from "react-icons/fa";
import Button from '@mui/material/Button';
import { useContext, useEffect, useState } from "react";
import { MyContext } from "../../App";

const QuantityBox = (props) => {
    const [inputVal, setInputVal] = useState(1); // Quantity value
    const context = useContext(MyContext);      // Context for alert messages

    // Ensure initial value is set from props
    useEffect(() => {
        if (props?.value !== undefined && props?.value !== null && props?.value !== "") {
            setInputVal(parseInt(props?.value));
        }
    }, [props.value]);

    // Decrease quantity
    const minus = () => {
        if (inputVal > 1) {
            setInputVal(inputVal - 1);
        }
        context.setAlertBox({ open: false });
    };

    // Increase quantity, ensuring it doesn't exceed stock
    const plus = () => {
        if (props.stock !== undefined && props.stock > 0) {
            if (inputVal < props.stock) {
                setInputVal(inputVal + 1);
            } else {
                context.setAlertBox({
                    open: true,
                    error: true,
                    msg: "The quantity exceeds the available stock"
                });
            }
        } else {
            context.setAlertBox({
                open: true,
                error: true,
                msg: "Out of stock"
            });
        }
    };

    // Handle manual input changes
    const handleInputChange = (e) => {
        let value = parseInt(e.target.value);
        let stock = props.stock ? parseInt(props.stock) : 0;

        if (!isNaN(value) && value > 0 && value <= stock) {
            setInputVal(value);
        } else if (value > stock) {
            context.setAlertBox({
                open: true,
                error: true,
                msg: "Entered quantity exceeds available stock"
            });
            setInputVal(stock); // Limit to available stock
        } else {
            setInputVal(1);  // Minimum quantity is 1
        }
    };

    // Notify parent component of the quantity change
    useEffect(() => {
        if (props.quantity) {
            props.quantity(inputVal);
        }
    }, [inputVal]);

    // Display the stock and quantity controls only if stock is available
    return (
        <div className='quantityDrop d-flex align-items-center'>
            {props.stock > 0 ? (
                <>
                    <Button onClick={minus} disabled={inputVal <= 1}><FaMinus /></Button>
                    <input 
                        type="text" 
                        value={inputVal} 
                        onChange={handleInputChange} 
                    />
                    <Button onClick={plus} disabled={inputVal >= props.stock}><FaPlus /></Button>
                </>
            ) : (
                <p className="out-of-stock-text">Out of Stock</p>
            )}
        </div>
    );
};

export default QuantityBox;
