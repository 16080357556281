import React, { useContext, useState, useEffect } from 'react';
import { Grid, Card, CardContent, Typography, List, ListItem, ListItemText, Divider, Button, TextField, CircularProgress } from '@mui/material';
import { IoBagCheckOutline } from "react-icons/io5";
import { MyContext } from '../../App';
import { useNavigate, useLocation } from 'react-router-dom';
import { postData } from '../../utils/api';
const PaymentMethods = () => {
    const { state } = useLocation();
    const { formFields, cartData, totalAmount } = state || {};
    const [loading, setLoading] = useState(false);
    const [selectedMethod, setSelectedMethod] = useState(null);  // Track selected payment method
    const [paymentDetails, setPaymentDetails] = useState({});  // Track payment details (phone number, card details, etc.)
    const [formValid, setFormValid] = useState(false);  // Track if payment form is valid
    const navigate = useNavigate();
    const context = useContext(MyContext);

    const paymentMethods = [
        { name: "Mobile Money (Momo Pay)", form: "mtn" },
        { name: "Airtel Money", form: "airtel" },
        { name: "Visa/MasterCard",form: "card" },
        { name: "PayPal",form: "email" }
    ];
    const history = useNavigate();
    const payloadmtn={
        amount:parseInt(totalAmount),
        phoneNumber:paymentDetails.mtn
    }
    const payloadairtel={
        amount:parseInt(totalAmount),
        phoneNumber:paymentDetails.airtel
    }
//    amount: parseInt(totalAmount * 100),
const addressInfo = {
    name: formFields.fullName,
    phoneNumber: formFields.phoneNumber,
    address: formFields.streetAddressLine1 + formFields.streetAddressLine2,
    pincode: formFields.zipCode,
    date: new Date().toLocaleString(
        "en-US",
        {
            month: "short",
            day: "2-digit",
            year: "numeric",
        }
    )
}
    const user = JSON.parse(localStorage.getItem("user"));


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // Handle form change
    const handleFormChange = (e) => {

        const { name, value } = e.target;
        setPaymentDetails((prev) => ({ ...prev, [name]: value }));

        

        // Form validation logic for the payment methods (simple validation)
        if (selectedMethod?.form === "mtn" && value.length >= 10) {
            // var num = "000-xxx-xxxx";
            var first_three = value.substr(0, 3);
       
            if (first_three === "079" || first_three === "078") {
                setFormValid(true);
            }
            
            // history("/orders");
            
        } 
        else if (selectedMethod?.form === 'airtel' && value.length >= 10) {
            var first_three = value.substr(0, 3);
       
            if (first_three === "073" || first_three === "072") {
                setFormValid(true);
            }
            // history("/orders");
            
        } 
        else if (selectedMethod?.form === 'card' && paymentDetails.cardNumber?.length === 16 && paymentDetails.cvv?.length === 3 && paymentDetails.expiryDate) {
            setFormValid(true);
        } else if (selectedMethod?.form === 'email' && value.includes('@')) {
            setFormValid(true);
        } else {
            setFormValid(false);
        }
    };
    const handleMtnPayment = () => {
        setLoading(true);
        // Simulate payment process (you can replace this with actual logic)
        setTimeout(() => {
        //    mtn payment method 

            postData(`/api/payment/mtn`, payloadmtn)
            .then(res => {
                console.log(res.status, "test mtn status----")
                if(res.status=="fail"){
                    context.setAlertBox({
                        open: true,
                        error: true,
                        msg: `Payment failed via ${selectedMethod.name}! please try again with message ${res.data.message}`
                    });
                    setLoading(false);

            }
            else if(res.status=="error"){
                context.setAlertBox({
                    open: true,
                    error: true,
                    msg: `Payment failed via ${selectedMethod.name}! please try again with message ${res.data.message}`
                });
                setLoading(false);

        }
            else{

                const mtnpayLoad = {
                    name: addressInfo.name,
                    phoneNumber: formFields.phoneNumber,
                    address: addressInfo.address,
                    pincode: addressInfo.pincode,
                    amount: parseInt(totalAmount),
                    paymentId: res.data.trxRef,
                    email: user.email,
                    userid: user.userId,
                    products: cartData
                }
                postData(`/api/orders/create`, mtnpayLoad)
                .then(orderRes => {
                   if(orderRes)
                   context.setAlertBox({
                       open: true,
                       error: false,
                       msg: `Payment successful via ${selectedMethod.name}!`
                   });
                   setLoading(false);
                   navigate('/orders');
              
           })

            }

            })

  
        }, 2000);
    };

    const handleAirtelPayment = () => {
        setLoading(true);
        // Simulate payment process (you can replace this with actual logic)
        setTimeout(() => {
        //    airtel payment method 

            postData(`/api/payment/airtel`, payloadairtel)
            .then(res => {
                console.log(res.status, "test airtel status----")
                if(res.status=="fail"){
                    context.setAlertBox({
                        open: true,
                        error: true,
                        msg: `Payment failed via ${selectedMethod.name}! please try again with message ${res.data.message}`
                    });
                    setLoading(false);

            }
            else if(res.status=="error"){
                context.setAlertBox({
                    open: true,
                    error: true,
                    msg: `Payment failed via ${selectedMethod.name}! please try again with message ${res.data.message}`
                });
                setLoading(false);

        }
            else{

                const airtelpayLoad = {
                    name: addressInfo.name,
                    phoneNumber: formFields.phoneNumber,
                    address: addressInfo.address,
                    pincode: addressInfo.pincode,
                    amount: parseInt(totalAmount),
                    paymentId: res.data.trxRef,
                    email: user.email,
                    userid: user.userId,
                    products: cartData
                }
                postData(`/api/orders/create`, airtelpayLoad)
                .then(orderRes => {
                   if(orderRes)
                   context.setAlertBox({
                       open: true,
                       error: false,
                       msg: `Payment successful via ${selectedMethod.name}!`
                   });
                   setLoading(false);
                   navigate('/orders');
              
           })

            }

            })

  
        }, 2000);
    };

    const handleCardPayment = () => {
        setLoading(true);
        // Simulate payment process (you can replace this with actual logic)
        setTimeout(() => {
        //    visa/master payment method 
  
            context.setAlertBox({
                open: true,
                error: true,
                msg: `This Payment ${selectedMethod.name} is not Available now please use on ther method`
            });
            setLoading(false);
            
 
            
  
        }, 2000);
    };

    const handlePalPayment = () => {
        setLoading(true);
        // Simulate payment process (you can replace this with actual logic)
        setTimeout(() => {
        //    mtn payment method 
 
            context.setAlertBox({
                open: true,
                error: true,
                msg: `This Payment ${selectedMethod.name} is not Available now please use on ther method`
            });
            setLoading(false);
            
 
            
  
        }, 2000);
    };
    return (
        <section className="section paymentPage">
            <div className="container">
                <Grid container spacing={3}>
                    {/* Order Summary on the left */}
                    <Grid item xs={12} md={6}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    ORDER SUMMARY
                                </Typography>
                                <List>
                                    {cartData.map((item) => (
                                        <ListItem key={item.id} alignItems="flex-start">
                                            <ListItemText
                                                primary={item.productTitle}
                                                secondary={(item.price * item.quantity).toLocaleString('en-US', { style: 'currency', currency: 'Rwf' })}
                                            />
                                        </ListItem>
                                    ))}
                                    <Divider />
                                    <ListItem>
                                        <ListItemText primary="Total" />
                                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                            {totalAmount?.toLocaleString('en-US', { style: 'currency', currency: 'Rwf' })}
                                        </Typography>
                                    </ListItem>
                                </List>
                            </CardContent>
                        </Card>

                        {/* Payment Methods */}
                        <Card variant="outlined" sx={{ mt: 3 }}>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    PAYMENT METHODS
                                </Typography>
                                <List>
                                    {paymentMethods.map((method, index) => (
                                        <ListItem
                                            key={index}
                                            button
                                            onClick={() => {
                                                setSelectedMethod(method);
                                                setPaymentDetails({});
                                                setFormValid(false);
                                            }}
                                            selected={selectedMethod?.name === method.name}
                                        >
                                            <ListItemText primary={method.name} />
                                        </ListItem>
                                    ))}
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Client Information and Payment Details */}
                    <Grid item xs={12} md={6}>
                        <Card variant="outlined" sx={{ mb: 3 }}>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    CLIENT INFORMATION
                                </Typography>
                                <Typography>
                                    <b>Full Name:</b> {formFields.fullName} <br />
                                    <b>Country:</b> {formFields.country} <br />
                                    <b>Street Address:</b> {formFields.streetAddressLine1}, {formFields.streetAddressLine2} <br />
                                    <b>City:</b> {formFields.city} <br />
                                    <b>State:</b> {formFields.state} <br />
                                    <b>ZIP Code:</b> {formFields.zipCode} <br />
                                    <b>Phone Number:</b> {formFields.phoneNumber} <br />
                                    <b>Email:</b> {formFields.email}
                                </Typography>
                            </CardContent>
                        </Card>

                        {/* Payment Details Form (appears next to payment methods) */}
                        {selectedMethod && (
                            <Card variant="outlined" sx={{ mb: 3 }}>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        ENTER PAYMENT DETAILS FOR {selectedMethod.name.toUpperCase()}
                                    </Typography>
                                    {selectedMethod.form === "mtn" && (
                                        <><TextField
                                            fullWidth
                                            label="MTN Phone Number"
                                            name="mtn"
                                            value={paymentDetails.mtn || ''}
                                            onChange={handleFormChange}
                                            placeholder="Enter your MTN mobile money number"
                                            />
                                            <CardContent>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth
                                                    disabled={!formValid || loading} // Disable if form is not valid or loading
                                                    onClick={handleMtnPayment}
                                                    sx={{ mt: 2 }}
                                                >
                                                    {loading ? <CircularProgress size={24} /> : <><IoBagCheckOutline /> Confirm and Pay</>}
                                                </Button>
                                            </CardContent>
                                            </>
                                    )}
                                    {selectedMethod.form === "airtel" && (
                                       <> <TextField
                                            fullWidth
                                            label="AIRTEL Phone Number"
                                            name="airtel"
                                            value={paymentDetails.airtel || ''}
                                            onChange={handleFormChange}
                                            placeholder="Enter your Airtel money number"
                                        />
                                        <CardContent>
                                         <Button
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth
                                                    disabled={!formValid || loading} // Disable if form is not valid or loading
                                                    onClick={handleAirtelPayment}
                                                    sx={{ mt: 2 }}
                                                >
                                                    {loading ? <CircularProgress size={24} /> : <><IoBagCheckOutline /> Confirm and Pay</>}
                                                </Button>
                                            </CardContent>
                                        
                                        </>
                                    )}
                                    {selectedMethod.form === "card" && (
                                        <>
                                            <TextField
                                                fullWidth
                                                label="Card Number"
                                                name="cardNumber"
                                                value={paymentDetails.cardNumber || ''}
                                                onChange={handleFormChange}
                                                placeholder="Enter your card number"
                                                sx={{ mt: 2 }}
                                            />
                                            <TextField
                                                fullWidth
                                                label="Expiry Date"
                                                name="expiryDate"
                                                value={paymentDetails.expiryDate || ''}
                                                onChange={handleFormChange}
                                                placeholder="MM/YY"
                                                sx={{ mt: 2 }}
                                            />
                                            <TextField
                                                fullWidth
                                                label="CVV"
                                                name="cvv"
                                                value={paymentDetails.cvv || ''}
                                                onChange={handleFormChange}
                                                placeholder="3-digit CVV"
                                                sx={{ mt: 2 }}
                                            />
                                        <CardContent>
                                         <Button
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth
                                                    disabled={!formValid || loading} // Disable if form is not valid or loading
                                                    onClick={handleCardPayment}
                                                    sx={{ mt: 2 }}
                                                >
                                                    {loading ? <CircularProgress size={24} /> : <><IoBagCheckOutline /> Confirm and Pay</>}
                                                </Button>
                                            </CardContent>
                                        </>
                                    )}
                                    {selectedMethod.form === "email" && (
                                      <> <TextField
                                            fullWidth
                                            label="Email"
                                            name="email"
                                            value={paymentDetails.email || ''}
                                            onChange={handleFormChange}
                                            placeholder="Enter your PayPal email"
                                        />
                                        <CardContent>
                                        <Button
                                                   variant="contained"
                                                   color="primary"
                                                   fullWidth
                                                   disabled={!formValid || loading} // Disable if form is not valid or loading
                                                   onClick={handlePalPayment}
                                                   sx={{ mt: 2 }}
                                               >
                                                   {loading ? <CircularProgress size={24} /> : <><IoBagCheckOutline /> Confirm and Pay</>}
                                               </Button>
                                           </CardContent>
                                           </> 
                                    )}
                                </CardContent>
                            </Card>
                        )}

                        {/* Confirm and Pay button (aligned with the payment form) */}
                        {/* {selectedMethod && (
                            <Card variant="outlined" sx={{ mt: 3 }}>
                                <CardContent>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        disabled={!formValid || loading}  // Disable if form is not valid or loading
                                        onClick={handlePayment}
                                        sx={{ mt: 2 }}
                                    >
                                        {loading ? <CircularProgress size={24} /> : <><IoBagCheckOutline /> Confirm and Pay</>}
                                    </Button>
                                </CardContent>
                            </Card>
                        )} */}
                    </Grid>
                </Grid>
            </div>
        </section>
    );
};

export default PaymentMethods;
