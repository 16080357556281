import { Link } from "react-router-dom";
import Rating from '@mui/material/Rating';
import { IoIosClose } from "react-icons/io";
import Button from '@mui/material/Button';
import emprtCart from '../../assets/images/myList.png';
import { MyContext } from "../../App";
import { useContext, useEffect, useState } from "react";
import { deleteData, fetchDataFromApi } from "../../utils/api";
import { FaHome } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

const MyList = () => {
    const [myListData, setMyListData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const context = useContext(MyContext);
    const [isLogin, setIsLogin] = useState(false);
    const history = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);

        const token = localStorage.getItem("token");
        if (!token) {
            history("/signIn");
            return;
        }

        const fetchData = async () => {
            setIsLoading(true);
            const user = JSON.parse(localStorage.getItem("user"));
            try {
                const res = await fetchDataFromApi(`/api/my-list?userId=${user?.userId}`);
                setMyListData(res);
            } catch (error) {
                console.error("Error fetching my list data", error);
                context.setAlertBox({
                    open: true,
                    error: true,
                    msg: "Failed to load My List!"
                });
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [context, history]);

    const removeItem = async (id) => {
        setIsLoading(true);
        try {
            await deleteData(`/api/my-list/${id}`);
            context.setAlertBox({
                open: true,
                error: false,
                msg: "Item removed from My List!"
            });
            const user = JSON.parse(localStorage.getItem("user"));
            const res = await fetchDataFromApi(`/api/my-list?userId=${user?.userId}`);
            setMyListData(res);
        } catch (error) {
            console.error("Error removing item", error);
            context.setAlertBox({
                open: true,
                error: true,
                msg: "Failed to remove item!"
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <section className="section cartPage">
                <div className="container">
                    <div className="myListTableWrapper">
                        <h2 className="hd mb-1">My List</h2>
                        <p>There are <b className="text-red">{myListData?.length}</b> products in your My List</p>
                        {myListData?.length !== 0 ? (
                            <div className="row">
                                <div className="col-md-12 pr-5">
                                    <div className="table-responsive myListTable">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th width="50%">Product</th>
                                                    <th width="15%">Unit Price</th>
                                                    <th width="10%">Remove</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {myListData?.map(({ _id, image, productTitle, rating, price, productId }) => (
                                                    <tr key={_id}>
                                                        <td width="50%">
                                                            <Link to={`/product/${productId}`}>
                                                                <div className="d-flex align-items-center cartItemimgWrapper">
                                                                    <div className="imgWrapper">
                                                                        <img src={image} className="w-100" alt={`Image of ${productTitle}`} />
                                                                    </div>
                                                                    <div className="info px-3">
                                                                        <h6>{productTitle}</h6>
                                                                        <Rating name="read-only" value={rating} readOnly size="small" />
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </td>
                                                        <td width="15%">Rwf {price}</td>
                                                        <td width="10%">
                                                            <span className="remove" onClick={() => removeItem(_id)}>
                                                                <IoIosClose />
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="empty d-flex align-items-center justify-content-center flex-column">
                                <img src={emprtCart} width="150" alt="Empty My List" />
                                <h3>My List is currently empty</h3>
                                <br />
                                <Link to="/">
                                    <Button className='btn-blue bg-red btn-lg btn-big btn-round'>
                                        <FaHome /> &nbsp; Continue Shopping
                                    </Button>
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            </section>
            {isLoading && <div className="loadingOverlay"></div>}
        </>
    );
}

export default MyList;
