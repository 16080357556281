import React, { useContext, useEffect, useState } from 'react';
import { TextField, Button, CircularProgress } from '@mui/material';
import { IoBagCheckOutline } from "react-icons/io5";
import { MyContext } from '../../App';
import { fetchDataFromApi } from '../../utils/api';
import { useNavigate } from 'react-router-dom';
import { Grid, Card, CardContent, Typography, List, ListItem, ListItemText, Divider } from '@mui/material';

const Checkout = () => {
    const [formFields, setFormFields] = useState({
        fullName: "",
        country: "",
        streetAddressLine1: "",
        streetAddressLine2: "",
        city: "",
        state: "",
        zipCode: "",
        phoneNumber: "",
        email: ""
    });

    const [cartData, setCartData] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);  // New error state

    const context = useContext(MyContext);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
        const user = JSON.parse(localStorage.getItem("user"));
        if (!user) return;

        // Auto-fill user data in form fields
        setFormFields({
            fullName: user?.fullName || "",
            country: user?.country || "",
            streetAddressLine1: user?.streetAddressLine1 || "",
            streetAddressLine2: user?.streetAddressLine2 || "",
            city: user?.city || "",
            state: user?.state || "",
            zipCode: user?.zipCode || "",
            phoneNumber: user?.phoneNumber || "",
            email: user?.email || ""
        });

        setLoading(true);
        fetchDataFromApi(`/api/cart?userId=${user?.userId}`)
            .then((res) => {
                if (res && res.length > 0) {
                    setCartData(res);
                    const total = res.map(item => parseInt(item.price) * item.quantity).reduce((total, value) => total + value, 0);
                    setTotalAmount(total);
                } else {
                    setError("Your cart is empty.");
                }
            })
            .catch(() => {
                setError("Failed to fetch cart data. Please try again.");
            })
            .finally(() => setLoading(false));
    }, []);

    const onChangeInput = (e) => {
        setFormFields({
            ...formFields,
            [e.target.name]: e.target.value
        });
    };

    const validateFields = () => {
        const requiredFields = ["fullName", "country", "streetAddressLine1", "city", "state", "zipCode", "phoneNumber", "email"];
        for (let field of requiredFields) {
            if (!formFields[field]) {
                context.setAlertBox({
                    open: true,
                    error: true,
                    msg: `Please fill ${field.replace(/([A-Z])/g, ' $1').toLowerCase()}`
                });
                return false;
            }
        }
        return true;
    };
    const handleCheckout = (e) => {
        e.preventDefault();
        if (!validateFields()) return;
    
        // Pass the form fields, cart data, and total amount to the PaymentMethods page
        navigate('/Payment-Methods', {
            state: {
                formFields,
                cartData,
                totalAmount
            }
        });
    };

    return (
        <section className="section">
            <div className="container">
                {loading ? <CircularProgress /> : (
                    <form className="checkoutForm" onSubmit={handleCheckout}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={8}>
                                <h2>BILLING DETAILS</h2>
                                <TextField label="Full Name *" name="fullName" value={formFields.fullName} onChange={onChangeInput} fullWidth required />
                                <TextField label="Country *" name="country" value={formFields.country} onChange={onChangeInput} fullWidth required />
                                <TextField label="House number and street name *" name="streetAddressLine1" value={formFields.streetAddressLine1} onChange={onChangeInput} fullWidth required />
                                <TextField label="City *" name="city" value={formFields.city} onChange={onChangeInput} fullWidth required />
                                <TextField label="State *" name="state" value={formFields.state} onChange={onChangeInput} fullWidth required />
                                <TextField label="ZIP Code *" name="zipCode" value={formFields.zipCode} onChange={onChangeInput} fullWidth required />
                                <TextField label="Phone Number *" name="phoneNumber" value={formFields.phoneNumber} onChange={onChangeInput} fullWidth required />
                                <TextField label="Email Address *" name="email" value={formFields.email} onChange={onChangeInput} fullWidth required />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography variant="h6" gutterBottom>
                                            YOUR ORDER
                                        </Typography>
                                        {error ? (
                                            <Typography color="error">{error}</Typography>
                                        ) : (
                                            <List>
                                                {cartData.map((item) => (
                                                    <ListItem key={item.id} alignItems="flex-start">
                                                        <ListItemText
                                                            primary={item.productTitle}
                                                            secondary={(item.price * item.quantity).toLocaleString('en-US', { style: 'currency', currency: 'Rwf' })}
                                                        />
                                                    </ListItem>
                                                ))}
                                                <Divider />
                                                <ListItem>
                                                    <ListItemText primary="Total" />
                                                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                                        {totalAmount.toLocaleString('en-US', { style: 'currency', currency: 'Rwf' })}
                                                    </Typography>
                                                </ListItem>
                                            </List>
                                        )}
                                        <Button 
                                            type="submit" 
                                            variant="contained" 
                                            color="primary" 
                                            fullWidth 
                                            disabled={loading || !cartData.length}
                                            sx={{ mt: 2 }}
                                        >
                                            {loading ? <CircularProgress size={24} /> : <><IoBagCheckOutline /> Proceed to Payment Methods</>}
                                        </Button>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </div>
        </section>
    );
};

export default Checkout;
