import ProductZoom from "../../Components/ProductZoom";
import Rating from "@mui/material/Rating";
import QuantityBox from "../../Components/QuantityBox";
import Button from "@mui/material/Button";
import { BsCartFill } from "react-icons/bs";
import { useContext, useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import RelatedProducts from "./RelatedProducts";
import { useParams, useNavigate } from "react-router-dom"; // Import useNavigate
import { fetchDataFromApi } from "../../utils/api"; // Adjust path if needed
import CircularProgress from "@mui/material/CircularProgress";
import { MyContext } from "../../App";

const ProductDetails = () => {
  const [activeTabs, setActiveTabs] = useState(0);
  const [activeSize, setActiveSize] = useState(null);
  const [productData, setProductData] = useState({});
  const [relatedProductData, setRelatedProductData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reviewsData, setReviewsData] = useState([]);
  const [productQuantity, setProductQuantity] = useState(1);
  const [tabError, setTabError] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate(); // Initialize useNavigate
  const context = useContext(MyContext);

  const isActive = (index) => {
    setActiveSize(index);
    setTabError(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setActiveSize(null);
    const fetchProductData = async () => {
      setIsLoading(true);
      try {
        const productPromise = fetchDataFromApi(`/api/products/${id}`);
        const relatedPromise = fetchDataFromApi(`/api/products/subCatId?subCatId=${productData?.subCatId}&location=${localStorage.getItem("location")}`);
        const reviewsPromise = fetchDataFromApi(`/api/productReviews?productId=${id}`);

        const [res, relatedRes, reviewsRes] = await Promise.all([productPromise, relatedPromise, reviewsPromise]);

        setProductData(res);
        setRelatedProductData(relatedRes?.products?.filter((item) => item.id !== id));
        setReviewsData(reviewsRes);

        if (!res?.productRam.length && !res?.productWeight.length && !res?.size.length) {
          setActiveSize(1);
        }
      } catch (error) {
        context.setAlertBox({
          open: true,
          error: true,
          msg: "Error fetching product data.",
        });
        console.error('Error fetching product data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProductData();
  }, [id, productData?.subCatId]);

  const addToCart = () => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      if (activeSize !== null) {
        const cartFields = {
          productTitle: productData?.name,
          image: productData?.images[0],
          rating: productData?.rating,
          price: productData?.price,
          quantity: productQuantity,
          subTotal: productData?.price * productQuantity,
          productId: productData?.id,
          countInStock: productData?.countInStock,
          userId: user?.userId,
        };
        context.addToCart(cartFields);
      } else {
        setTabError(true);
      }
    } else {
      context.setAlertBox({
        open: true,
        error: true,
        msg: "Please sign in to add products to the cart.",
      });
      navigate('/Signin'); // Redirect to login page
    }
  };

  return (
    <>
      <section className="productDetails section">
        <div className="container">
          <div className="row">
            <div className="col-md-4 pl-5 part1">
              {isLoading ? (
                <CircularProgress />
              ) : (
                <ProductZoom images={productData?.images} discount={productData?.discount} />
              )}
            </div>

            <div className="col-md-7 pl-5 pr-5 part2">
              <h2 className="hd text-capitalize">{productData?.name}</h2>
              <ul className="list list-inline d-flex align-items-center">
                <li className="list-inline-item">
                  <div className="d-flex align-items-center">
                    <span className="text-light mr-2">Brands: </span>
                    <span>{productData?.brand}</span>
                  </div>
                </li>

                <li className="list-inline-item">
                  <div className="d-flex align-items-center">
                    <Rating name="read-only" value={parseInt(productData?.rating)} precision={0.5} readOnly size="small" />
                    <span className="text-light cursor ml-2" onClick={() => setActiveTabs(2)}>
                      {reviewsData?.length} Review
                    </span>
                  </div>
                </li>
              </ul>

              <div className="d-flex info mb-3">
                <span className="oldPrice">Rwf: {productData?.oldPrice}</span>
                <span className="netPrice text-danger ml-2">Rwf: {productData?.price}</span>
              </div>

              {tabError && <p className="text-danger">Please select a size before adding to cart.</p>}

              <p className="mt-3">{productData?.description}</p>

              <QuantityBox quantity={(val) => setProductQuantity(val)} />

              <div className="d-flex align-items-center mt-3">
                <Button
                  className="btn btn-primary mr-3"
                  variant="contained"
                  style={{
                    backgroundColor: '#d32f2f',
                    color: 'white',
                    padding: '10px 20px',
                    borderRadius: '20px',
                    fontSize: '16px',
                  }}
                  onClick={addToCart}
                  disabled={productData?.countInStock === 0} // Disable button if out of stock
                  aria-label="Add to Cart"
                >
                  <BsCartFill className="mr-2" /> Add to Cart
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Optionally render related products */}
      <RelatedProducts products={relatedProductData} />
    </>
  );
};

export default ProductDetails;
